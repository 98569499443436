export type ValidationError = 'INITIAL_AMOUNT_ABOVE_LIMIT' | 'AGE_ABOVE_LIMIT';

export const AGE_LIMIT = 50;
export const INITIAL_AMOUNT_LIMIT = 1_000_000;

export const validateCalculatorInput = (
  age: number | undefined,
  initialAmount: number | undefined
): ValidationError | null => {
  if (age && age > AGE_LIMIT) {
    return 'AGE_ABOVE_LIMIT';
  }

  if (initialAmount && initialAmount > INITIAL_AMOUNT_LIMIT) {
    return 'INITIAL_AMOUNT_ABOVE_LIMIT';
  }

  return null;
};
