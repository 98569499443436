import {
  MAX_ELIGIBLE_AGE,
  MAX_POLICY_AGE,
  MAX_TERM,
  MIN_ELIGIBLE_AGE,
} from 'content/life-insurance/coverage';
import { SmokingStatus } from 'models/life-insurance';

export const isIneligibleAge = (ageInput: number) =>
  ageInput < MIN_ELIGIBLE_AGE || ageInput > MAX_ELIGIBLE_AGE;

export const smokerStatus = (isSmoker: boolean): SmokingStatus =>
  isSmoker ? 'smoker' : 'nonSmoker';

// Returns a hypothetical birthdate which takes birth year from age, birth month and date from current date
// e.g. 30 yo -> birthdate is 30 years ago today
export const getBirthdateFromAge = (age: number) => {
  const today = new Date();
  const [year, month, date] = today.toISOString().split('T')[0].split('-');
  const birthyear = parseInt(year, 10) - age;
  const birthdate = `${birthyear}${month}${date}`;
  return parseInt(birthdate, 10);
};

export const getMaxTermByAge = (age?: number): number => {
  if (age) {
    return Math.min(MAX_POLICY_AGE - age, MAX_TERM);
  }
  return MAX_TERM;
};
