import {
  calculateLiabilityPricing,
  InclusiveMember,
} from '@getpopsure/liability-insurance-pricing-engine';
import { insurance } from '@getpopsure/private-constants';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { illustrations } from '@popsure/dirty-swan';
import CtaLink from 'components/CtaLink';
import PriceCalculatorContainer from 'components/priceCalculatorContainer';
import { inclusiveMembersInfo } from 'content/liability-insurance/inclusiveMembersList';
import { useClient } from 'hooks/useClient';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { setInclusiveMembersInLocalStorage } from 'util/liability-insurance';

import styles from './style.module.scss';

const PricingCalculatorSection = ({
  sectionId,
  hasBackgroundColor,
}: {
  sectionId: string;
  hasBackgroundColor: boolean;
}) => {
  const [inclusiveMembers, setInclusiveMembers] = useState<InclusiveMember[]>(
    []
  );

  const hasMounted = useClient();

  const { t } = useTranslation();

  const price = calculateLiabilityPricing(inclusiveMembers);

  useEffect(() => {
    if (hasMounted) {
      setInclusiveMembersInLocalStorage(inclusiveMembers); // store the value in localStorage so that this param can be accessed globally
    }
  }, [inclusiveMembers, hasMounted]);

  const handleOnChange = (value: InclusiveMember) => {
    const isAlreadyIncluded = inclusiveMembers?.includes(value);
    let newMembers: InclusiveMember[] = [];

    if (isAlreadyIncluded) {
      newMembers = inclusiveMembers.filter(
        (selectedValue) => selectedValue !== value
      );
    } else {
      newMembers = [...inclusiveMembers, value];
    }

    setInclusiveMembers(newMembers);
  };

  return (
    <div className="p-body">
      <PriceCalculatorContainer
        sectionId={sectionId}
        hasBackgroundColor={hasBackgroundColor}
      >
        <div className={`w100 d-flex ai-center jc-between ${styles.container}`}>
          <div className={`w100 ${styles['radio-container']}`}>
            <div className="d-flex ai-center">
              <div className="tc-grey-700 p-h2 mr8">
                {t('page.liability.section.calculate.title')}
              </div>
              <Image
                src={illustrations.heart}
                alt="purple heart"
                className="mr8"
                width={24}
                height={25}
              />
            </div>
            <div className="mt8 p-p tc-grey-600">
              {t('page.liability.section.calculate.description')}
            </div>
            <div
              className={`p-label-container mt8 ${styles['label-container']}`}
            >
              {inclusiveMembersInfo(t).map(({ title, value }) => (
                <div className="mt8" key={value}>
                  <input
                    className="p-checkbox"
                    id={value}
                    type="checkbox"
                    value={value}
                    onChange={() => handleOnChange(value)}
                    checked={inclusiveMembers?.includes(value)}
                  />
                  <label
                    htmlFor={value}
                    className={`p-label p-label--bordered mr8 ${styles.label}`}
                  >
                    {title}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className={`w100 wmx4 ${styles['pricing-container']}`}>
            <div className={styles['cta-container']}>
              <div className="p--serif p-h1 tc-primary-500">
                {englishFormattedEuroCurrency(price, 2)}
              </div>
              <div className="tc-primary-500 mt8 p-p--small">
                {t('page.liability.section.calculate.month', 'per month')}
              </div>
            </div>
            <CtaLink
              className="mt24 p-btn--primary wmn2"
              href={insurance.liability.signup}
            >
              {t('page.liability.section.calculate.action')}
            </CtaLink>
          </div>
        </div>
      </PriceCalculatorContainer>
    </div>
  );
};

export default PricingCalculatorSection;
