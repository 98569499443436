import {
  BottomOrRegularModal,
  Button,
  CurrencyInput,
} from '@popsure/dirty-swan';
import { MAX_COVERAGE, MIN_COVERAGE } from 'content/life-insurance/coverage';
import { useTranslation } from 'next-i18next';
import { FormEvent, useEffect, useState } from 'react';
import { formatNumber } from 'util/formatNumber';

import styles from './style.module.scss';
import { ErrorType, validate } from './validation';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onChange: (value: number) => void;
  coverage: number;
}

const CoverageModal = ({ isOpen, onClose, onChange, coverage }: Props) => {
  const [value, setValue] = useState(coverage);
  const [error, setError] = useState<ErrorType | null>(null);
  const { t } = useTranslation();

  const errorMessageMapping: { [K in ErrorType]: string } = {
    UNDER_MIN: t('page.life.coveragemodal.error.undermin', {
      defaultValue:
        'The smallest coverage amount we can offer is {{minCoverage}}',
      minCoverage: MIN_COVERAGE,
    }),
    OVER_MAX: t('page.life.coveragemodal.error.overmax', {
      defaultValue:
        'The maximum coverage amount we can offer is {{maxCoverage}}',
      maxCoverage: MAX_COVERAGE,
    }),
  };

  useEffect(() => {
    setValue(coverage);
  }, [coverage, isOpen]);

  useEffect(() => {
    const validationError = validate(value);

    if (validationError) {
      setError(validationError);
    } else {
      setError(null);
    }
  }, [value]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (error) {
      return;
    }

    onChange(value);
    onClose();
  };

  return (
    <BottomOrRegularModal
      title={t('page.life.coveragemodal.title', 'Coverage amount')}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form className={styles.modal} onSubmit={(e) => onSubmit(e)}>
        <p className="p-p wmx5">
          {t(
            'page.life.coveragemodal.description',
            'Please enter your desired coverage amount ranging from'
          )}{' '}
          {formatNumber(MIN_COVERAGE)} to {formatNumber(MAX_COVERAGE)}.
        </p>
        <CurrencyInput
          className="mt16 wmx5"
          placeholder={t('page.life.coveragemodal.placeholder', 'Coverage')}
          prefix="€"
          value={value}
          onChange={(v) => setValue(v)}
          error={(error && errorMessageMapping[error]) ?? undefined}
          required
        />
        <Button className="wmn3 mt16" disabled={!value}>
          {t('page.life.coveragemodal.button', 'Apply')}
        </Button>
      </form>
    </BottomOrRegularModal>
  );
};

export default CoverageModal;
