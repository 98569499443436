import { calendly } from '@getpopsure/private-constants';
import { BottomOrRegularModal, Button, Input } from '@popsure/dirty-swan';
import Markdown from 'components/markdown';
import { MIN_TERM } from 'content/life-insurance/coverage';
import { useTranslation } from 'next-i18next';
import { FormEvent, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import styles from './style.module.scss';
import { ErrorType, validate } from './validation';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onChange: (value: number) => void;
  term: number;
  maxTerm: number;
}

const TermModal = ({ isOpen, onClose, onChange, term, maxTerm }: Props) => {
  const [value, setValue] = useState(term);
  const [error, setError] = useState<ErrorType | null>(null);
  const { t } = useTranslation();

  const errorMapping: { [K in ErrorType]: string } = {
    UNDER_MIN: t('page.life.termmodal.error.undermin', {
      defaultValue: 'Our minimum term of coverage is {{minTerm}} years',
      minTerm: MIN_TERM,
    }),
    OVER_MAX: ` `,
  };

  useEffect(() => {
    setValue(term);
  }, [term, isOpen]);

  useEffect(() => {
    const validationError = validate(value, maxTerm);

    if (validationError) {
      setError(validationError);
    } else {
      setError(null);
    }
  }, [value, maxTerm]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (error) {
      return;
    }

    onChange(value);
    onClose();
  };

  return (
    <BottomOrRegularModal
      title={t('page.life.termmodal.title', 'Term of coverage')}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form className={styles.modal} onSubmit={(e) => onSubmit(e)}>
        <p className="p-p">
          {t(
            'page.life.termmodal.description',
            'Please enter the desired number of years below: '
          )}
        </p>
        <Input
          className="mt16 wmx5"
          placeholder={t('page.life.termmodal.placeholder', 'Term (years)')}
          value={value ? String(value) : ''}
          onChange={(e) => setValue(Number(e.target.value))}
          pattern="[0-9]*"
          type="text"
          error={(error && errorMapping[error]) ?? undefined}
        />
        <AnimateHeight
          duration={300}
          height={error === 'OVER_MAX' ? 'auto' : 0}
        >
          <div className="p-notice p-notice--primary mt16 wmx5 p-p--small">
            <p>
              {t('page.life.termmodal.error.overmax', {
                defaultValue:
                  'We can only offer you a maximum term of {{maxTerm}} years.',
                maxTerm,
              })}
            </p>
            <p className="mt16">
              <Markdown styling={{ p: 'p-p--small' }}>
                {t('page.life.termmodal.moreinfo', {
                  defaultValue:
                    'Do you have more questions? [Reach out to us]({{link}}) for more information.',
                  link: calendly.lifeInsurance,
                })}
              </Markdown>
            </p>
          </div>
        </AnimateHeight>
        <Button className="wmn3 mt16" disabled={!value}>
          Apply
        </Button>
      </form>
    </BottomOrRegularModal>
  );
};

export default TermModal;
