// TODO: Extract pricing logic out of website
// Should all pricing engines (eg. Private, Public) live in one lib for future engines?

import { BikeType, Price, PriceLimit } from '../models/bike';

const standardBikeBasicPricing: PriceLimit[] = [
  {
    limit: 1000.01,
    price: 34.8,
  },
  {
    limit: 1500.01,
    price: 44.4,
  },
  {
    limit: 2000.01,
    price: 49.2,
  },
  {
    limit: 2500.01,
    price: 58.8,
  },
  {
    limit: 3000.01,
    price: 60,
  },
  {
    limit: 4000.01,
    price: 103.2,
  },
  {
    limit: 5000.01,
    price: 135.6,
  },
  {
    limit: 6000.01,
    price: 166.8,
  },
  {
    limit: 7000.01,
    price: 192,
  },
  {
    limit: 8000.01,
    price: 225.6,
  },
  {
    limit: 9000.01,
    price: 261.6,
  },
  {
    limit: 10000.01,
    price: 297.6,
  },
];

const standardBikePremiumPricing: PriceLimit[] = [
  {
    limit: 1000.01,
    price: 52.8,
  },
  {
    limit: 1500.01,
    price: 63.6,
  },
  {
    limit: 2000.01,
    price: 69.6,
  },
  {
    limit: 3000.01,
    price: 80.4,
  },
  {
    limit: 4000.01,
    price: 134.4,
  },
  {
    limit: 5000.01,
    price: 169.2,
  },
  {
    limit: 6000.01,
    price: 204,
  },
  {
    limit: 7000.01,
    price: 226.8,
  },
  {
    limit: 8000.01,
    price: 262.8,
  },
  {
    limit: 9000.01,
    price: 298.8,
  },
  {
    limit: 10000.01,
    price: 334.8,
  },
];

const eBikeBasicPricing: PriceLimit[] = [
  {
    limit: 1000.01,
    price: 43.2,
  },
  {
    limit: 1500.01,
    price: 48,
  },
  {
    limit: 2000.01,
    price: 52.8,
  },
  {
    limit: 2500.01,
    price: 57.6,
  },
  {
    limit: 3000.01,
    price: 63.6,
  },
  {
    limit: 4000.01,
    price: 82.8,
  },
  {
    limit: 4500.01,
    price: 111.6,
  },
  {
    limit: 5000.01,
    price: 123.6,
  },
  {
    limit: 5500.01,
    price: 135.6,
  },
  {
    limit: 6000.01,
    price: 148.8,
  },
  {
    limit: 7000.01,
    price: 171.6,
  },
  {
    limit: 8000.01,
    price: 205.2,
  },
  {
    limit: 9000.01,
    price: 230.4,
  },
  {
    limit: 10000.01,
    price: 266.4,
  },
];

const eBikePremiumPricing: PriceLimit[] = [
  {
    limit: 1000.01,
    price: 64.8,
  },
  {
    limit: 1500.01,
    price: 68.4,
  },
  {
    limit: 2000.01,
    price: 74.4,
  },
  {
    limit: 2500.01,
    price: 79.2,
  },
  {
    limit: 3000.01,
    price: 85.2,
  },
  {
    limit: 4000.01,
    price: 109.2,
  },
  {
    limit: 4500.01,
    price: 142.8,
  },
  {
    limit: 5000.01,
    price: 154.8,
  },
  {
    limit: 5500.01,
    price: 166.8,
  },
  {
    limit: 6000.01,
    price: 178.8,
  },
  {
    limit: 7000.01,
    price: 202.8,
  },
  {
    limit: 8000.01,
    price: 238.8,
  },
  {
    limit: 9000.01,
    price: 262.8,
  },
  {
    limit: 10000.01,
    price: 298.8,
  },
];

const mapPricingByType: {
  [K in BikeType]: {
    basicPricing: PriceLimit[];
    premiumPricing: PriceLimit[];
  };
} = {
  BIKE: {
    basicPricing: standardBikeBasicPricing,
    premiumPricing: standardBikePremiumPricing,
  },
  E_BIKE: {
    basicPricing: eBikeBasicPricing,
    premiumPricing: eBikePremiumPricing,
  },
};

export const monthlyPayment = (annualPrice: number): number =>
  Number((annualPrice / 12).toFixed(2));

export const calculateBikePricing = (
  bikePrice?: number,
  type?: BikeType
): Price | null => {
  if (!bikePrice || !type) {
    return null;
  }

  const { basicPricing, premiumPricing } = mapPricingByType[type];
  const annualBasicPrice = basicPricing.find(
    ({ limit }) => bikePrice < limit
  )?.price;
  const annualPremiumPrice = premiumPricing.find(
    ({ limit }) => bikePrice < limit
  )?.price;

  if (!annualBasicPrice || !annualPremiumPrice) {
    return null;
  }

  return {
    basic: monthlyPayment(annualBasicPrice),
    comfort: monthlyPayment(annualPremiumPrice),
  };
};
