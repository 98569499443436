import { MIN_TERM } from 'content/life-insurance/coverage';

export type ErrorType = 'UNDER_MIN' | 'OVER_MAX';

export const validate = (term: number, maxTerm: number): ErrorType | null => {
  if (term < MIN_TERM) {
    return 'UNDER_MIN';
  }

  if (term > maxTerm) {
    return 'OVER_MAX';
  }

  return null;
};
