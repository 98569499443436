import {
  getHouseholdPrices,
  isValidPostcode,
} from '@getpopsure/household-insurance-pricing-engine';
import { insurance } from '@getpopsure/private-constants';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { BottomOrRegularModal, InfoIcon, Input } from '@popsure/dirty-swan';
import classNames from 'classnames';
import Markdown from 'components/markdown';
import { SliderWithModal } from 'components/SliderWithModal';
import { useTranslation } from 'next-i18next';
import { useRef, useState } from 'react';

import styles from './HouseholdCalculator.module.scss';

const MINIMUM_INSURED_SUM = 10_000;
const MAXIMUM_INSURED_SUM = 250_000;
const DEFAULT_INSURED_SUM = 45_000;
const SLIDER_STEP_SIZE = 1000;
const MINIMUM_MONTHLY_PREMIUM = 2.33;

export const HouseholdCalculator = ({
  hasBackgroundColor,
}: {
  hasBackgroundColor: boolean;
}): JSX.Element => {
  const [amountModalOpen, setAmountModalOpen] = useState(false);
  const [insuredSum, setInsuredSum] = useState(DEFAULT_INSURED_SUM);
  const [postalCode, setPostalCode] = useState('');
  const postalCodeInputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const householdPrices = isValidPostcode(postalCode)
    ? getHouseholdPrices({
        postcode: postalCode,
        propertyInSqMeters: 0,
        insuredSum,
        hasSecurityDevice: false,
        bikeInsuredSum: 0,
      })
    : null;

  const price = householdPrices?.basicPremium || 0;
  const showBelowMinimumFootNote = price > 0 && price <= 2.33;

  const priceStr = householdPrices?.basicPremium
    ? englishFormattedEuroCurrency(
        Math.max(MINIMUM_MONTHLY_PREMIUM, householdPrices.basicPremium),
        2
      )
    : '€ —';

  return (
    <div
      className={classNames('p-body mxauto my0', styles.container, {
        [styles.containerWithBgColor]: hasBackgroundColor,
      })}
    >
      <h2 className="p-h2 p--serif">
        {t('household:section.calculator.title', 'Get a price estimate')}
      </h2>
      <div className={styles.bgPattern} />
      <div
        className={`ml-auto mr-auto mt24 pt48 pb48 pl56 pr56 br8 bg-white ${styles.calculator}`}
      >
        <div className="d-flex f-wrap ai-center jc-center c-gap72 r-gap40">
          <div className={`w100 ${styles.calculatorInputs}`}>
            <Input
              ref={postalCodeInputRef}
              className="mb32"
              id="postalCode"
              type="text"
              value={postalCode}
              label={t(
                'household:section.calculator.postalcode.label',
                'Postal code'
              )}
              placeholder="10961"
              pattern="[0-9]{1,5}"
              onChange={(e) => {
                if (postalCodeInputRef.current?.reportValidity()) {
                  setPostalCode(e.target.value);
                }
              }}
            />
            <SliderWithModal
              shortenNumbers
              label={t(
                'household:section.calculator.slider.label',
                'Insured sum'
              )}
              min={MINIMUM_INSURED_SUM}
              max={MAXIMUM_INSURED_SUM}
              step={SLIDER_STEP_SIZE}
              value={insuredSum}
              setValue={setInsuredSum}
              modalTextOverrides={{
                title: t(
                  'household:section.calculator.inputmodal.title',
                  'Insured sum'
                ),
                description: t(
                  'household:section.calculator.inputmodal.description',
                  {
                    defaultValue:
                      'Please enter your desired insured sum ranging from {{min}} to {{max}}.',
                    min: englishFormattedEuroCurrency(MINIMUM_INSURED_SUM, 0),
                    max: englishFormattedEuroCurrency(MAXIMUM_INSURED_SUM, 0),
                  }
                ),
                placeholder: t(
                  'household:section.calculator.inputmodal.placeholder',
                  'Coverage'
                ),
                belowMinErrorMessage: t(
                  'household:section.calculator.inputmodal.error.belowmin',
                  {
                    defaultValue:
                      'The smallest insured sum we can offer is {{min}}',
                    min: englishFormattedEuroCurrency(MINIMUM_INSURED_SUM, 0),
                  }
                ),
                aboveMaxErrorMessage: t(
                  'household:section.calculator.inputmodal.error.abovemax',
                  {
                    defaultValue:
                      'The insured sum exceeds the maximum we can offer ({{max}})',
                    max: englishFormattedEuroCurrency(MAXIMUM_INSURED_SUM, 0),
                  }
                ),
                buttonCaption: t(
                  'household:section.calculator.inputmodal.button.caption',
                  'Apply'
                ),
              }}
            />
            <button
              className="p-a mt40 fw-bold c-pointer bg-transparent"
              type="button"
              onClick={() => setAmountModalOpen(true)}
            >
              {t(
                'household:section.calculator.amountmodal.link.text',
                'What amount should I choose?'
              )}
            </button>
            <BottomOrRegularModal
              isOpen={amountModalOpen}
              onClose={() => setAmountModalOpen(false)}
              title={t(
                'household:section.calculator.amountmodal.title',
                'What amount should I choose?'
              )}
            >
              <div className="pt16 px24 pb24">
                <Markdown className="p-p wmx6">
                  {t(
                    'household:section.calculator.amountmodal.description',
                    "The amount should be enough to cover all the belongings in your home, including appliances and furniture owned by your landlord; if you're unsure about their value, consult your landlord or make an estimate.\n\nYou can start with the most expensive items, then estimate the cost of everything else in groups — electronics, furniture, clothing, etc.\n\nIf you’re sharing a flat, the policy must cover all the rooms and everyone’s belongings. You can split the cost of the policy with your flatmates afterwards."
                  )}
                </Markdown>
              </div>
            </BottomOrRegularModal>
          </div>
          <div
            className={`d-flex fd-column ai-center w100 ${styles.calculatorOutput}`}
          >
            <p className="p-p--small tc-grey-600 mb4">
              {t('household:section.calculator.from', 'From')}
            </p>
            <h3 className="p-h1--xl p--serif tc-primary-500 ta-center">
              {priceStr}
            </h3>
            <p className="p-p fw-bold tc-primary-500 ta-center p--serif">
              {t('household:section.calculator.permonth', 'per month')}
            </p>
            {showBelowMinimumFootNote && (
              <p
                className={`d-inline-flex gap4 ai-center p-p--small tc-grey-600 ${styles.footnote}`}
              >
                <InfoIcon color="primary-500" size={20} />
                {t(
                  'household:section.calculator.minimummonthlyplan',
                  'Minimum monthly plan'
                )}
              </p>
            )}
            <a
              className={`p-btn p-btn--primary mt24 w100 ${styles.quoteButton}`}
              href={insurance.household.signup}
              target="_blank"
              rel="noreferrer"
            >
              {t('household:section.calculator.cta.title', 'Get a quote')}
            </a>
          </div>
        </div>
      </div>{' '}
    </div>
  );
};
