import { MAX_COVERAGE, MIN_COVERAGE } from 'content/life-insurance/coverage';

export type ErrorType = 'UNDER_MIN' | 'OVER_MAX';

export const validate = (coverage: number): ErrorType | null => {
  if (coverage < MIN_COVERAGE) {
    return 'UNDER_MIN';
  }

  if (coverage > MAX_COVERAGE) {
    return 'OVER_MAX';
  }

  return null;
};
