import { BottomOrRegularModal } from '@popsure/dirty-swan';
import { useTranslation } from 'next-i18next';

import styles from './style.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const InfoModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <BottomOrRegularModal
      title={t('page.life.howtomodal.title', 'How to pick term and coverage')}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={`tc-grey-700 ${styles.modal}`}>
        <div className="p-h4 ">
          {t('page.life.howtomodal.years.title', 'For how many years?')}
        </div>
        <p className="p-p mt8">
          {t(
            'page.life.howtomodal.years.description',
            'We recommend getting coverage until your mortgage is paid off or your youngest child has completed school or university (whichever comes later). Depending on your situation, you can adjust your coverage accordingly.'
          )}
        </p>
        <div className="p-h4 mt16">
          {t(
            'page.life.howtomodal.coverage.title',
            'How much coverage do I need?'
          )}
        </div>
        <p className="p-p mt8">
          {t(
            'page.life.howtomodal.coverage.description',
            'Use the following criteria to figure out how much coverage you need:'
          )}
        </p>
        <ul className={`p-p mt8 ${styles.list}`}>
          <li>
            {t(
              'page.life.howtomodal.coverage.1.item',
              'We recommend €10k per child per year until they are 18 (or 23 if you want to finance their university)'
            )}
          </li>
          <li>
            {t(
              'page.life.howtomodal.coverage.2.item',
              'If you have a mortgage, we recommend adding the full cost of the mortgage until it is paid'
            )}
          </li>
          <li>
            {t(
              'page.life.howtomodal.coverage.3.item4',
              'If your partner is financially dependant on you, we recommend 3 - 5 years of income'
            )}
          </li>
        </ul>
        <p className="p-p mt16">
          {t(
            'page.life.howtomodal.coverage.finaladvice',
            'Too complicated? A lot of experts recommend 3 - 5x your current gross yearly salary as a good approximation.'
          )}
        </p>
      </div>
    </BottomOrRegularModal>
  );
};

export default InfoModal;
