import { Provider } from '@getpopsure/public-health-insurance-pricing-engine';
import classNames from 'classnames';
import BikeCalculator from 'components/BikeCalculator';
import HouseholdCalculator from 'components/HouseholdCalculator';
import LiabilityCalculator from 'components/liability-insurance/pricingCalculator';
import LifeCalculator from 'components/LifeQuoteWidget';
import PensionCalculator from 'components/PensionCalculator';
import StandalonePublicQuoteWidget from 'components/PublicTableWithCalculator/standalonePublicQuoteWidget';
import { BlockComponentProps } from 'strapi/types/utils';

import styles from './Calculator.module.scss';

export const Calculator = ({
  block,
}: BlockComponentProps<'marketing-website.calculator'>) => {
  const {
    has_background_color: hasBackgroundColor,
    vertical: verticalWithProvider,
    section_id: sectionId,
  } = block;

  const [vertical, provider = ''] = verticalWithProvider.split('.');

  return (
    <section
      className={classNames('mt56', styles.container, {
        [styles.background]: hasBackgroundColor,
      })}
    >
      {vertical === 'LIABILITY' && (
        <LiabilityCalculator
          sectionId={sectionId ?? 'calculator'}
          hasBackgroundColor={hasBackgroundColor}
        />
      )}
      {vertical === 'LIFE' && (
        <LifeCalculator hasBackgroundColor={hasBackgroundColor} />
      )}
      {vertical === 'HOUSEHOLD' && (
        <HouseholdCalculator hasBackgroundColor={hasBackgroundColor} />
      )}
      {vertical === 'BIKE' && (
        <BikeCalculator hasBackgroundColor={hasBackgroundColor} />
      )}
      {vertical === 'PENSION' && (
        <PensionCalculator hasBackgroundColor={hasBackgroundColor} />
      )}
      {vertical === 'PUBLIC_HEALTH' && (
        <StandalonePublicQuoteWidget
          provider={provider as Provider | undefined}
        />
      )}
    </section>
  );
};
