import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { CurrencyInput } from '@popsure/dirty-swan';
import PriceCalculatorContainer from 'components/priceCalculatorContainer';
import { BikeType } from 'models/bike';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { calculateBikePricing } from 'util/bikePriceCalculator';

import styles from './style.module.scss';

export const BikeCalculator = ({
  hasBackgroundColor,
}: {
  hasBackgroundColor: boolean;
}) => {
  const [bikeType, setBikeType] = useState<BikeType | undefined>(undefined);
  const [bikePrice, setBikePrice] = useState<number | undefined>(undefined);
  const [animatePrice, setAnimatePrice] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setAnimatePrice(false);
    const timeout = setTimeout(() => {
      setAnimatePrice(true);
    }, 300);
    return () => clearTimeout(timeout);
  }, [bikeType, bikePrice]);

  const prices = calculateBikePricing(bikePrice, bikeType);

  return (
    <div className="p-body">
      <PriceCalculatorContainer
        title={t('bike:section.calculate.title')}
        hasBackgroundColor={hasBackgroundColor}
      >
        <div className={`d-flex jc-between ${styles.priceCalculatorContainer}`}>
          <form className="ws5">
            <CurrencyInput
              placeholder={t('bike:section.calculate.buyingprice.input')}
              value={bikePrice}
              onChange={(value) => setBikePrice(value)}
            />
            <div className="d-flex mt16">
              <div className="p-label-container w50 mr16">
                <input
                  className="p-radio"
                  id="BIKE"
                  name="bikeType"
                  type="radio"
                  value="BIKE"
                  checked={bikeType === 'BIKE'}
                  onChange={() => setBikeType('BIKE')}
                />
                <label className="p-label p-label--bordered" htmlFor="BIKE">
                  {t('bike:section.calculate.selector.bike')}
                </label>
              </div>
              <div className="p-label-container w50">
                <input
                  className="p-radio"
                  name="bikeType"
                  id="E_BIKE"
                  type="radio"
                  value="E_BIKE"
                  checked={bikeType === 'E_BIKE'}
                  onChange={() => setBikeType('E_BIKE')}
                />
                <label className="p-label p-label--bordered" htmlFor="E_BIKE">
                  {t('bike:section.calculate.selector.ebike')}
                </label>
              </div>
            </div>
          </form>
          <div
            className={`ws6 d-flex ai-center jc-between ${styles.priceCalculatorContentContainer}`}
          >
            <div className="ta-center w50">
              <div className="p--serif p-h4">
                {t('bike:section.calculate.basic')}
              </div>
              <div
                className={`p-h1 tc-primary-500 p--serif mt8 ${
                  animatePrice ? styles.animate : styles.hide
                }`}
              >
                {prices?.basic
                  ? englishFormattedEuroCurrency(prices?.basic)
                  : '€ —'}
              </div>
              <p className="p-p tc-grey-500">
                {t('bike:section.calculate.basic.month')}
              </p>
            </div>
            <div className={`ta-center w50 ${styles.premiumContentContainer}`}>
              <div className="p--serif p-h4">
                {t('bike:section.calculate.premium')}
              </div>
              <div
                className={`p-h1 tc-primary-500 p--serif mt8 ${
                  animatePrice ? styles.animate : styles.hide
                }`}
              >
                {prices?.comfort
                  ? englishFormattedEuroCurrency(prices?.comfort)
                  : '€ —'}
              </div>
              <p className="p-p tc-grey-500">
                {' '}
                {t('bike:section.calculate.premium.month')}
              </p>
            </div>
          </div>
        </div>
      </PriceCalculatorContainer>
    </div>
  );
};
