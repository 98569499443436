import { captureException } from '@sentry/nextjs';
import { useState } from 'react';

export const useEndpoint = () => {
  const [data, setData] = useState<Response | null>(null);
  const [error, setError] = useState<unknown | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchEndpoint = async (apiRequest: () => Promise<Response>) => {
    setLoading(true);
    setError(null);

    try {
      const response = await apiRequest();
      setData(response);
    } catch (err) {
      captureException(err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { fetchEndpoint, data, error, setError, loading };
};
