import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { EditableSlider } from '../EditableSlider';
import { InputModal } from './InputModal';

type ModalTextOverrides = {
  aboveMaxErrorMessage?: string;
  belowMinErrorMessage?: string;
  buttonCaption?: string;
  description?: string;
  placeholder?: string;
  title?: string;
};

type SliderWithModalProps = {
  darkSliderBackground?: boolean;
  className?: string;
  disabled?: boolean;
  hideKnobAndValue?: boolean;
  label: string;
  max: number;
  min: number;
  modalTextOverrides?: ModalTextOverrides;
  modalSetValue?: (value: number) => void;
  recommendedValue?: number;
  setValue: (value: number) => void;
  shortenNumbers?: boolean;
  step?: number;
  value: number;
};

export const SliderWithModal = ({
  darkSliderBackground = false,
  className,
  disabled = false,
  hideKnobAndValue = false,
  label,
  max,
  min,
  modalTextOverrides,
  modalSetValue,
  setValue,
  shortenNumbers = false,
  recommendedValue,
  step = 1,
  value,
}: SliderWithModalProps) => {
  const [showInputModal, setShowInputModal] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <InputModal
        isOpen={showInputModal}
        min={min}
        max={max}
        onClose={() => setShowInputModal(false)}
        setValue={modalSetValue || setValue}
        value={value}
        title={
          modalTextOverrides?.title ||
          t('components.sliderwithmodal.defaults.modal.title', 'Coverage')
        }
        description={
          modalTextOverrides?.description ||
          t('components.sliderwithmodal.defaults.modal.description', {
            defaultValue:
              'Please enter your desired amount ranging from {{min}} to {{max}}.',
            min: englishFormattedEuroCurrency(min, 0),
            max: englishFormattedEuroCurrency(max, 0),
          })
        }
        placeholder={
          modalTextOverrides?.placeholder ||
          t('components.sliderwithmodal.defaults.modal.placeholder', 'Coverage')
        }
        belowMinErrorMessage={
          modalTextOverrides?.belowMinErrorMessage ||
          t('components.sliderwithmodal.defaults.modal.error.belowmin', {
            defaultValue: 'The smallest amount we can offer is {{min}}',
            min: englishFormattedEuroCurrency(min, 0),
          })
        }
        aboveMaxErrorMessage={
          modalTextOverrides?.aboveMaxErrorMessage ||
          t('components.sliderwithmodal.defaults.modal.error.abovemax', {
            defaultValue:
              'The entered amount exceeds the maximum we can offer ({{max}})',
            max: englishFormattedEuroCurrency(max, 0),
          })
        }
        buttonCaption={
          modalTextOverrides?.buttonCaption ||
          t(
            'components.sliderwithmodal.defaults.modal.error.button.caption',
            'Apply'
          )
        }
      />
      <EditableSlider
        options={{
          darkBG: darkSliderBackground,
          hideKnobAndValue,
          shortenNumbers,
        }}
        className={className}
        disabled={disabled}
        label={label}
        min={min}
        max={max}
        recommendedValue={recommendedValue}
        onEditClick={() => {
          setShowInputModal(true);
        }}
        setValue={setValue}
        step={step}
        value={value}
      />
    </>
  );
};
