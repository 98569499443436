import { insurance } from '@getpopsure/private-constants';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { illustrations, Input } from '@popsure/dirty-swan';
import { captureException } from '@sentry/nextjs';
import classnames from 'classnames';
import { EditableSlider } from 'components/EditableSlider';
import PriceCalculatorContainer from 'components/priceCalculatorContainer';
import {
  MAX_COVERAGE,
  MAX_TERM,
  MIN_COVERAGE,
  MIN_TERM,
} from 'content/life-insurance/coverage';
import { submitLifeQuoteRequest } from 'endpoint';
import { useEndpoint } from 'hooks/useEndpoint';
import debounce from 'lodash.debounce';
import {
  ActionStatus,
  CustomerDetails,
  FetchStatus,
  Modal,
} from 'models/life-insurance';
import Image from 'next/image';
import { Trans, useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import {
  getBirthdateFromAge,
  getMaxTermByAge,
  isIneligibleAge,
  smokerStatus,
} from 'util/lifeQuoteWidget';

import CoverageModal from './coverageModal';
import InfoModal from './infoModal';
import styles from './LifeQuoteWidget.module.scss';
import TermModal from './termModal';

const hasMissingDetails = (details: Partial<CustomerDetails>) =>
  !details.age ||
  details.isSmoker === undefined ||
  !details.termYears ||
  !details.coverage;

export const LifeQuoteWidget = ({
  hasBackgroundColor,
}: {
  hasBackgroundColor: boolean;
}) => {
  const { t } = useTranslation();
  const { fetchEndpoint, data, loading, error } = useEndpoint();

  const [openModal, setOpenModal] = useState<Modal | null>(null);
  const [customerDetails, setCustomerDetails] = useState<
    Partial<CustomerDetails>
  >({
    isSmoker: false,
    termYears: MAX_TERM,
    coverage: MAX_COVERAGE,
  });
  const { age, isSmoker, termYears, coverage } = customerDetails;

  const [fetchStatus, setFetchStatus] = useState<FetchStatus>('IDLE');
  const [actionStatus, setActionStatus] = useState<ActionStatus | null>(null);
  const [price, setPrice] = useState<number | null>(null);

  const resetCalculator = () => {
    setPrice(null);
    setActionStatus(null);
  };

  const handleDetailChange = (details: Partial<CustomerDetails>) => {
    resetCalculator();

    if (hasMissingDetails(details)) return;

    if (isIneligibleAge(details.age as number)) {
      setActionStatus('INELIGIBLE_AGE');
      return;
    }

    setFetchStatus('FETCHING');
    debouncedFetchQuote(details);
  };

  const fetchQuote = async (details: Partial<CustomerDetails>) => {
    if (hasMissingDetails(details)) return;

    fetchEndpoint(async () =>
      submitLifeQuoteRequest({
        birthdate: getBirthdateFromAge(details.age as number),
        coverage: details.coverage as number,
        duration: details.termYears as number,
        smoker: smokerStatus(details.isSmoker as boolean),
      })
    );
  };
  const debouncedFetchQuote = debounce(fetchQuote, 500);

  useEffect(() => {
    if (!data) return;

    const handleFetchQuote = async () => {
      const response = await data.json();
      if (response?.status === 'ok') {
        const premium = response.premium[0].monthly_gross_premium;
        if (premium === 0) {
          setActionStatus('INELIGIBLE_TERMS');
        } else {
          setPrice(premium);
        }
        setFetchStatus('IDLE');
      } else {
        captureException('An error occurred fetching Life quote');
      }
    };
    handleFetchQuote();
  }, [data]);

  useEffect(() => {
    if (error) {
      setFetchStatus('ERROR');
    }
  }, [error]);

  if (fetchStatus === 'ERROR') {
    return (
      <PriceCalculatorContainer
        title={t('page.life.section.calculate.title')}
        hasBackgroundColor={hasBackgroundColor}
      >
        <div className="ta-center p-p tc-grey-600">
          <div>Sorry, something went wrong.</div>
          <div>Please try again shortly.</div>
        </div>
      </PriceCalculatorContainer>
    );
  }
  return (
    <div className="p-body">
      <InfoModal
        isOpen={openModal === 'INFO'}
        onClose={() => setOpenModal(null)}
      />
      <TermModal
        isOpen={openModal === 'TERM'}
        onClose={() => setOpenModal(null)}
        onChange={(newTerm: number) => {
          const newDetails = {
            ...customerDetails,
            termYears: newTerm,
          };
          setCustomerDetails(newDetails);
          handleDetailChange(newDetails);
        }}
        term={termYears as number}
        maxTerm={getMaxTermByAge(customerDetails.age)}
      />
      <CoverageModal
        isOpen={openModal === 'COVERAGE'}
        onClose={() => setOpenModal(null)}
        onChange={(newCoverage: number) => {
          const newDetails = {
            ...customerDetails,
            coverage: newCoverage,
          };
          setCustomerDetails(newDetails);
          handleDetailChange(newDetails);
        }}
        coverage={coverage as number}
      />
      <PriceCalculatorContainer
        title={t('page.life.section.calculate.title')}
        hasBackgroundColor={hasBackgroundColor}
      >
        <div
          className={styles.priceCalculatorContainer}
          style={{
            justifyContent:
              actionStatus === 'INELIGIBLE_AGE' ||
              actionStatus === 'INELIGIBLE_TERMS'
                ? 'space-between'
                : 'unset',
          }}
        >
          <div className="d-block">
            <div className={styles.priceCalculatorInputContainer}>
              <div className={`mr24 mb16 ${styles.ageInputContainer}`}>
                <label
                  htmlFor="life-insurance-calculator-age"
                  className="p-p tc-grey-600"
                >
                  Your age
                </label>
                <Input
                  className="mt16 wmx4"
                  value={age ? String(age) : ''}
                  onChange={(e) => {
                    const newDetails = {
                      ...customerDetails,
                      age: Number(e.target.value),
                    };
                    setCustomerDetails(newDetails);
                    handleDetailChange(newDetails);
                  }}
                  type="text"
                  placeholder="Age"
                  pattern="[0-9]*"
                  id="life-insurance-calculator-age"
                />
              </div>
              <div className="mb16">
                <p className="p-p tc-grey-600">Do you smoke?</p>
                <div className="d-flex mt16">
                  <input
                    name="isSmoker"
                    className="p-radio"
                    type="radio"
                    id="is-smoker-yes"
                    checked={isSmoker === true}
                    onChange={() => {
                      const newDetails = {
                        ...customerDetails,
                        isSmoker: true,
                      };
                      setCustomerDetails(newDetails);
                      handleDetailChange(newDetails);
                    }}
                    required
                  />
                  <label
                    htmlFor="is-smoker-yes"
                    className={`p-label p-label--bordered mr8 ${styles.radioLabel}`}
                  >
                    {t('page.life.section.calculate.input.issmoker.yes')}
                  </label>
                  <input
                    name="isSmoker"
                    className="p-radio"
                    type="radio"
                    id="is-smoker-no"
                    checked={isSmoker === false}
                    onChange={() => {
                      const newDetails = {
                        ...customerDetails,
                        isSmoker: false,
                      };
                      setCustomerDetails(newDetails);
                      handleDetailChange(newDetails);
                    }}
                    required
                  />
                  <label
                    htmlFor="is-smoker-no"
                    className={`p-label p-label--bordered ${styles.radioLabel}`}
                  >
                    {t('page.life.section.calculate.input.issmoker.no')}
                  </label>
                </div>
              </div>
            </div>
            <div className="w100 mt8">
              <EditableSlider
                options={{
                  darkBG: true,
                  noFormatting: true,
                }}
                min={MIN_TERM}
                max={MAX_TERM}
                value={termYears as number}
                setValue={(value) =>
                  setCustomerDetails({
                    ...customerDetails,
                    termYears: value,
                  })
                }
                onChangeStart={() => setFetchStatus('DISABLED')}
                onChangeEnd={() => handleDetailChange(customerDetails)}
                step={1}
                label={t(
                  'page.life.section.calculate.input.term.label',
                  'Term (years):'
                )}
                onEditClick={() => setOpenModal('TERM')}
              />
            </div>
            <div className="w100 mt24">
              <EditableSlider
                options={{
                  darkBG: true,
                  shortenNumbers: true,
                }}
                min={MIN_COVERAGE}
                max={MAX_COVERAGE}
                value={coverage as number}
                setValue={(value) =>
                  setCustomerDetails({
                    ...customerDetails,
                    coverage: value,
                  })
                }
                onChangeStart={() => setFetchStatus('DISABLED')}
                onChangeEnd={() => handleDetailChange(customerDetails)}
                step={5000}
                label={t(
                  'page.life.section.calculate.input.coverage.label',
                  'Coverage:'
                )}
                onEditClick={() => setOpenModal('COVERAGE')}
              />
            </div>
            <button
              type="button"
              className={`c-pointer p-a mt24 ${styles.priceCalculatorModalButton}`}
              onClick={() => setOpenModal('INFO')}
            >
              How should I choose?
            </button>
          </div>
          <div
            className={classnames(styles.priceCalculatorActionContainer, {
              'm-auto':
                actionStatus !== 'INELIGIBLE_AGE' &&
                actionStatus !== 'INELIGIBLE_TERMS',
            })}
          >
            <div
              className={`d-flex fd-column ai-center jc-center ${styles.priceCalculatorActionContent}`}
            >
              {actionStatus === 'INELIGIBLE_AGE' && (
                <>
                  <Image
                    src={illustrations.person}
                    alt="purple woman"
                    width={56}
                    height={56}
                  />
                  <div className="p-h4 mt16 wmx4">
                    {t(
                      'page.life.section.calculate.ineligible.age.title',
                      'Unfortunately, we can only cover people between 18 & 65 years old.'
                    )}
                  </div>
                  <p className="p-p mt8 wmx4">
                    {t(
                      'page.life.section.calculate.ineligible.age.description',
                      'Given that our sign-up is digital and doesn’t require medical checks, we can only cover this specific age group.'
                    )}
                  </p>
                </>
              )}
              {actionStatus === 'INELIGIBLE_TERMS' && (
                <>
                  <Image
                    src={illustrations.person}
                    alt="purple woman"
                    width={56}
                    height={56}
                  />
                  <p className="p-p mt8 wmx4">
                    <Trans i18nKey="page.life.section.calculate.ineligible.terms.description">
                      Unfortunately, this combination of age and length of
                      coverage is not available in our online sign up. Please{' '}
                      <a className="p-a" href="/support">
                        contact us
                      </a>
                      .
                    </Trans>
                  </p>
                </>
              )}
              {actionStatus !== 'INELIGIBLE_TERMS' &&
                actionStatus !== 'INELIGIBLE_AGE' && (
                  <>
                    <>
                      <p className="p-p--small tc-grey-500">from</p>
                      {price ? (
                        <h3 className="p-h1--xl tc-primary-500 p--serif">
                          {englishFormattedEuroCurrency(price, 2)}
                        </h3>
                      ) : (
                        <>
                          {fetchStatus === 'FETCHING' || loading ? (
                            <div className="ds-spinner ds-spinner__l" />
                          ) : (
                            <h3 className="p-h1 tc-primary-500 p--serif mt16">
                              € —
                            </h3>
                          )}
                        </>
                      )}
                      <p className="p-p--small tc-grey-500">
                        {t(
                          'page.life.section.calculate.quote.occurence',
                          'per month'
                        )}
                      </p>
                    </>
                    <a
                      className={classnames(`p-btn--primary wmn2 mt16`, {
                        'p-btn--secondary': !price,
                      })}
                      href={insurance.life.signup}
                    >
                      {t(
                        'page.life.section.calculate.cover.action',
                        'Get a quote'
                      )}
                    </a>
                  </>
                )}
            </div>
          </div>
        </div>
      </PriceCalculatorContainer>
    </div>
  );
};
