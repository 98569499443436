import { Trans, useTranslation } from '@getpopsure/i18n-react';
import {
  computeIndustryUpFrontFee,
  computePensionReturn,
} from '@getpopsure/pension-insurance-calculator';
import { insurance } from '@getpopsure/private-constants';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { CurrencyInput, Input } from '@popsure/dirty-swan';
import { captureException } from '@sentry/nextjs';
import classNames from 'classnames';
import Markdown from 'components/markdown';
import PriceCalculatorContainer from 'components/priceCalculatorContainer';
import { SliderWithModal } from 'components/SliderWithModal';
import { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import styles from './style.module.scss';
import {
  AGE_LIMIT,
  validateCalculatorInput,
  ValidationError,
} from './validation';

const MINIMUM_MONTHLY_PAYMENT = 50;
const MAXIMUM_MONTHLY_PAYMENT = 1_000;
const DEFAULT_MONTHLY_PAYMENT = 150;
const DEFAULT_PENSION_AGE = 67;
const FIXED_MONTHLY_FEE = 1.5;
const YEARLY_FEE_PERCENTAGE = 0.5;
const YEARLY_YIELD_PERCENTAGE = 6;
const SLIDER_STEP_SIZE = 10;

export type CalculatorError = ValidationError | 'SUBMISSION_ERROR';

export const PensionCalculator = ({
  hasBackgroundColor,
}: {
  hasBackgroundColor: boolean;
}) => {
  const { t } = useTranslation();

  const [age, setAge] = useState<number | undefined>();
  const [initialAmount, setInitialAmount] = useState<number | undefined>();
  const [monthlyPayment, setMonthlyPayment] = useState<number>(
    DEFAULT_MONTHLY_PAYMENT
  );
  const [error, setError] = useState<string | null>();

  const errorMessageMapping: Record<CalculatorError, string> = {
    AGE_ABOVE_LIMIT: t(
      'page.pension.section.calculate.ageLimit.description',
      '**You need to be under {{maxAge}}**\n\nUnfortunately you can only contribute to private pension insurance until you’re {{maxAge}}.',
      { maxAge: AGE_LIMIT }
    ),
    INITIAL_AMOUNT_ABOVE_LIMIT: t(
      'page.pension.section.calculate.oneTimePayment.title',
      '**The one-time payment should be lower than €1 million**'
    ),
    SUBMISSION_ERROR: t(
      'page.pension.section.calculate.error',
      "**Something didn't work.** Try refreshing the page to address the issue."
    ),
  };

  useEffect(() => {
    const validationError = validateCalculatorInput(age, initialAmount);
    setError(validationError);
  }, [age, initialAmount]);

  const canCalculatePension = age && monthlyPayment;

  const handlePensionCalculation = () => {
    if (!canCalculatePension) {
      return '€ —';
    }
    const computedPensionReturn = computePensionReturn({
      duration: { years: DEFAULT_PENSION_AGE - age },
      initialAmount: initialAmount ?? 0,
      monthlyPayment,
      fixedMonthlyFee: FIXED_MONTHLY_FEE,
      yearlyFeePercentage: YEARLY_FEE_PERCENTAGE,
      yearlyYieldPercentage: YEARLY_YIELD_PERCENTAGE,
    });

    if (!computedPensionReturn.ok) {
      captureException(`[Pension calculator] ${computedPensionReturn.error}`);
      setError('SUBMISSION_ERROR');
      return null;
    }

    return englishFormattedEuroCurrency(
      Math.floor(computedPensionReturn.value),
      true
    );
  };

  const handleUpFrontFeeCalculation = () => {
    if (!canCalculatePension) {
      return null;
    }

    const computedIndustryUpFrontFee = computeIndustryUpFrontFee({
      duration: { years: DEFAULT_PENSION_AGE - age },
      initialAmount: initialAmount ?? 0,
      monthlyPayment,
    });

    if (!computedIndustryUpFrontFee.ok) {
      captureException(
        `[Pension calculator] ${computedIndustryUpFrontFee.error}`
      );
      setError('SUBMISSION_ERROR');
      return null;
    }

    return computedIndustryUpFrontFee.value;
  };

  const pensionReturn = handlePensionCalculation();
  const fee = handleUpFrontFeeCalculation();

  const isErrorKey = (key: string): key is CalculatorError => {
    return key in errorMessageMapping;
  };

  return (
    <section className="p-body">
      <PriceCalculatorContainer
        title={t(
          'page.pension.section.calculate.title',
          'Estimate your pension'
        )}
        hasBackgroundColor={hasBackgroundColor}
        additionalInfo={
          <AnimateHeight
            duration={300}
            height={fee ? 'auto' : 0}
            className=" w100 ai-center mt16"
          >
            <div
              className={classNames(
                styles.feesTable,
                'w100 bg-white d-flex fd-column px16 py16 br16'
              )}
            >
              <div className="d-flex fd-row jc-between ai-center">
                <div className="mb24 p-h3 ws3">
                  {t(
                    'pension.quote.calculator.feesTable.compareTheFees',
                    'Compare the fees'
                  )}
                </div>
                <div className="mb24 p-h4 ws3 ta-center mx8">
                  {t(
                    'pension.quote.calculator.feesTable.industryStandard',
                    'Industry standard'
                  )}
                </div>
                <div className="mb24 p-h4 bg-primary-100 py8 px8 ws3 ta-center br8">
                  {t(
                    'pension.quote.calculator.feesTable.featherPension',
                    'Feather pension'
                  )}
                </div>
              </div>
              <div className="d-flex fd-row jc-between">
                <div className="mb24 ws3 d-flex ai-center">
                  <p className="p-p mr8">
                    <Trans i18nKey="pension.quote.calculator.feesTable.upFrontFee">
                      Up-front <p className={styles.showTablet}>fee</p>
                    </Trans>
                  </p>
                </div>
                <div className="mb24 p-p ws3 text ta-center">
                  {englishFormattedEuroCurrency(fee ?? 0, true)}
                </div>
                <div className="mb24 p-h3 ws3 ta-center">€0</div>
              </div>
              <div className="d-flex fd-row jc-between">
                <div className="p-p ws3 d-flex ai-center">
                  <p className="p-p mr8">
                    <Trans i18nKey="pension.quote.calculator.feesTable.annualFee">
                      Annual <p className={styles.showTablet}>fee</p>
                    </Trans>
                  </p>
                </div>
                <div className="p-p ws3 ta-center">1.5%</div>
                <div className="p-h3 ws3 ta-center">0.7%</div>
              </div>
            </div>
          </AnimateHeight>
        }
      >
        <div
          className={classNames(
            'd-flex jc-between fd-column gap40',
            styles.calculatorContainer
          )}
        >
          <form>
            <div
              className={classNames(
                'd-flex gap16 fd-column',
                styles.inputContainer
              )}
            >
              <div className={classNames(styles.ageInput)}>
                <Input
                  className="mt8 w100"
                  placeholder="30"
                  value={age ? String(age) : ''}
                  onChange={(e) => setAge(Number(e.target.value))}
                  type="number"
                  id="pension-insurance-calculator-age"
                  label={t('page.pension.section.calculate.age.label', 'Age')}
                />
              </div>
              <div className={classNames(styles.oneTimePaymentInput)}>
                <CurrencyInput
                  className="mt8 w100"
                  placeholder="0"
                  value={initialAmount}
                  onChange={(value) => setInitialAmount(value)}
                  id="pension-insurance-calculator-one-time-payment"
                  label={t(
                    'page.pension.section.calculate.oneTimePayment.label',
                    'One-time payment'
                  )}
                />
              </div>
            </div>
            <div
              className={classNames('mt32', styles.monthlyContributionsInput)}
            >
              <SliderWithModal
                label={t(
                  'page.pension.section.calculate.monthlyContributions.label',
                  'Monthly contributions'
                )}
                step={SLIDER_STEP_SIZE}
                min={MINIMUM_MONTHLY_PAYMENT}
                max={MAXIMUM_MONTHLY_PAYMENT}
                setValue={setMonthlyPayment}
                value={monthlyPayment}
                modalTextOverrides={{
                  title: t(
                    'page.pension.section.calculate.monthlyContributions.modal.title',
                    'Monthly contributions'
                  ),
                  description: t(
                    'page.pension.section.calculate.monthlyContributions.modal.description',
                    {
                      defaultValue:
                        'Please enter your desired monthly contribution ranging from {{min}} to {{max}}.',
                      min: englishFormattedEuroCurrency(
                        MINIMUM_MONTHLY_PAYMENT,
                        true
                      ),
                      max: englishFormattedEuroCurrency(
                        MAXIMUM_MONTHLY_PAYMENT,
                        true
                      ),
                    }
                  ),
                  placeholder: t(
                    'page.pension.section.calculate.monthlyContributions.modal.placeholder',
                    'Monthly contributions'
                  ),
                  belowMinErrorMessage: t(
                    'page.pension.section.calculate.monthlyContributions.modal.belowmin',
                    {
                      defaultValue:
                        'The insured sum exceeds the minimum amount {{min}}',
                      min: englishFormattedEuroCurrency(
                        MINIMUM_MONTHLY_PAYMENT,
                        true
                      ),
                    }
                  ),
                  aboveMaxErrorMessage: t(
                    'page.pension.section.calculate.monthlyContributions.modal.abovemax',
                    {
                      defaultValue:
                        'The insured sum exceeds the maximum amount {{max}}',
                      max: englishFormattedEuroCurrency(
                        MAXIMUM_MONTHLY_PAYMENT,
                        true
                      ),
                    }
                  ),
                  buttonCaption: t(
                    'page.pension.section.calculate.monthlyContributions.modal.button.caption',
                    'Apply'
                  ),
                }}
              />
            </div>
          </form>
          <>
            {error && isErrorKey(error) ? (
              <div
                className={classNames(
                  'd-flex fd-column jc-center ai-center',
                  styles.resultContainer,
                  styles.warning
                )}
              >
                <Markdown styling={{ p: 'ta-center p-p mt8' }}>
                  {errorMessageMapping[error]}
                </Markdown>
              </div>
            ) : (
              <div
                className={classNames(
                  'd-flex fd-column jc-center ai-center',
                  styles.resultContainer
                )}
              >
                <p className="p-p--small tc-grey-500">
                  {t(
                    'page.pension.section.calculate.result.approx',
                    'Approximately'
                  )}
                </p>
                <h3 className="p--serif p-h1--xl tc-primary-500">
                  {pensionReturn}
                </h3>
                {Boolean(canCalculatePension) && (
                  <>
                    <Markdown
                      styling={{
                        p: 'p-p--small ta-center tc-grey-500 mt8 ws4',
                      }}
                    >
                      {t(
                        'page.pension.section.calculate.result.details',
                        'based on an average profit of **{{yearlyYieldPercentage}} per year** until the age of {{pensionAge}}, including fees.',
                        {
                          yearlyYieldPercentage: String(
                            YEARLY_YIELD_PERCENTAGE
                          ).concat('%'),
                          pensionAge: DEFAULT_PENSION_AGE,
                        }
                      )}
                    </Markdown>
                    <a
                      className="p-btn p-btn--primary ws4 mt24"
                      href={insurance.pension.signup}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t(
                        'page.pension.section.calculate.button.caption',
                        'Sign up'
                      )}
                    </a>
                  </>
                )}
              </div>
            )}
          </>
        </div>
      </PriceCalculatorContainer>
    </section>
  );
};
