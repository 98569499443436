import { Provider } from '@getpopsure/public-health-insurance-pricing-engine';
import { ArrowRightIcon, Card } from '@popsure/dirty-swan';
import PriceCalculatorContainer from 'components/priceCalculatorContainer';
import { useCellReplacements } from 'components/PublicTableWithCalculatorV2/hooks/useCellReplacements';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';

import { isEligibleForPrivate } from '../lib/isEligibleForPrivate';
import { CalculatorVariables, PublicQuoteWidget } from '../publicQuoteWidget';
import styles from '../style.module.scss';

const StandalonePublicQuoteWidget = ({ provider }: { provider?: Provider }) => {
  const [calculatorVariables, setCalculatorVariables] = useState<
    Partial<CalculatorVariables>
  >({});
  const { defaultPrice } = useCellReplacements({
    options: calculatorVariables,
    handleOnCalculateClick: () => {},
    providerFilter: provider,
  });
  const { t } = useTranslation();

  const handleCalculatorChange = (
    key: keyof CalculatorVariables,
    value?: CalculatorVariables[keyof CalculatorVariables]
  ) =>
    setCalculatorVariables({
      ...calculatorVariables,
      [key]: value,
    });

  const eligibleForPrivate = isEligibleForPrivate({
    age: calculatorVariables.age,
    employmentStatus: calculatorVariables?.employmentStatus,
    income: calculatorVariables.annualIncome,
  });

  return (
    <article className={styles.calculator}>
      <div className="p-body">
        <PriceCalculatorContainer
          title={t('publichealth:section.calculator.title')}
          hasBackgroundColor
          additionalInfo={
            <AnimateHeight
              duration={300}
              height={eligibleForPrivate ? 'auto' : 0}
            >
              <Card
                as="a"
                icon={
                  <Image
                    src="/static/icons/insurances/private-health.svg"
                    alt={t(
                      'publichealth:section.calculator.privathealthcta.image.alt'
                    )}
                    width={48}
                    height={48}
                  />
                }
                href="/health-insurance/private"
                density="compact"
                classNames={{
                  buttonWrapper: 'mt24',
                  icon: 'p16',
                  title: 'd-flex ai-center jc-between',
                }}
                title={
                  <>
                    {t('publichealth:section.calculator.privathealthcta.title')}

                    <ArrowRightIcon color="primary-500" size={20} />
                  </>
                }
                titleVariant="medium"
                description={t(
                  'publichealth:section.calculator.privathealthcta.description'
                )}
                actionIcon={null}
              />
            </AnimateHeight>
          }
        >
          <PublicQuoteWidget
            variables={calculatorVariables}
            onChange={handleCalculatorChange}
            total={defaultPrice}
          />
        </PriceCalculatorContainer>
      </div>
    </article>
  );
};

export default StandalonePublicQuoteWidget;
