import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { formatNumber } from 'util/formatNumber';

import EditIcon from './assets/pen.svg';
import styles from './EditableSlider.module.scss';

export type SliderOptions = {
  darkBG?: boolean;
  hideKnobAndValue?: boolean;
  shortenNumbers?: boolean;
  bubbleVariant?: boolean;
  noFormatting?: boolean;
};

type EditableSliderProps = {
  className?: string;
  disabled?: boolean;
  label: string;
  max: number;
  min: number;
  onEditClick: () => void;
  recommendedValue?: number;
  setValue: (value: number) => void;
  step: number;
  value: number;
  onChangeStart?: () => void;
  onChangeEnd?: () => void;
  options?: SliderOptions;
};

export const EditableSlider = ({
  options,
  className = '',
  disabled = false,
  label,
  max,
  min,
  onEditClick,
  onChangeStart,
  onChangeEnd,
  recommendedValue,
  setValue,
  step,
  value,
}: EditableSliderProps) => {
  const { t } = useTranslation();

  const recommendedValuePercentage = recommendedValue
    ? ((recommendedValue - min) / (max - min)) * 100
    : 0;

  const currentValuePercentage = value
    ? ((value - min) / (max - min)) * 100
    : 0;

  const nDigitsShown = Number.isInteger(value) ? 0 : 2;

  const valueStr = getValueStr(value, nDigitsShown, options);
  const minStr = options?.noFormatting
    ? min
    : formatNumber(min, options?.shortenNumbers);
  const maxStr = options?.noFormatting
    ? max
    : formatNumber(max, options?.shortenNumbers);

  return (
    <div
      className={`w100 ${className} ${styles.container} ${
        disabled ? styles.disabled : ''
      }`}
    >
      {!options?.bubbleVariant && (
        <>
          <div className="d-flex ai-center c-gap8">
            <p className="p-p fw-bold tc-grey-600">{label}</p>
            <span className="tc-grey-900">{valueStr}</span>
            <button
              className={styles.editButton}
              onClick={onEditClick}
              disabled={disabled}
              type="button"
              aria-label="edit"
            >
              <Image src={EditIcon} alt="edit icon" />
            </button>
          </div>
          {recommendedValue && (
            <p
              className={`p-p--small fw-bold tc-grey-500 ${styles.weRecommend}`}
            >
              {`${t(
                'components.editableslider.werecommend.text',
                'We recommend'
              )} ${formatNumber(recommendedValue, options?.shortenNumbers)}`}
            </p>
          )}
        </>
      )}
      <div className={styles.sliderContainer}>
        <div
          className={`${styles.sliderBackground} ${
            options?.darkBG ? styles.darkBG : ''
          }`}
        />
        <input
          className={`${styles.slider} ${
            options?.hideKnobAndValue ? styles.hideKnob : ''
          }`}
          type="range"
          min={min}
          max={max}
          value={value}
          onChange={(e) => setValue(Number(e.target.value))}
          step={step}
          disabled={disabled}
          onMouseDown={() => onChangeStart?.()}
          onTouchStart={() => onChangeStart?.()}
          onMouseUp={() => onChangeEnd?.()}
          onTouchEnd={() => onChangeEnd?.()}
        />
        {options?.bubbleVariant && !options?.hideKnobAndValue && (
          <div
            className={`${styles.valueBubbleContainer}`}
            style={{
              left: `calc(${currentValuePercentage}%)`,
            }}
          >
            <div className={`d-flex c-gap8 br8 ${styles.valueBubble}`}>
              <p className="p-p tc-grey-700">{valueStr}</p>
              <button
                className={styles.editButton}
                onClick={onEditClick}
                disabled={disabled}
                type="button"
                aria-label="edit"
              >
                <Image src={EditIcon} alt="edit icon" />
              </button>
            </div>
          </div>
        )}
        <div
          className={styles.recommendedDot}
          style={{
            left: `${recommendedValuePercentage}%`,
            transform: `translateX(${
              (recommendedValuePercentage / 100) * -18 + 4
            }px)`,
          }}
        />
      </div>
      <p className={`p-p tc-grey-600 ${styles.legend}`}>
        <span>{minStr}</span>
        <span>{maxStr}</span>
      </p>
    </div>
  );
};

const getValueStr = (
  value: number,
  nDigitsShown: number,
  options?: SliderOptions
) => {
  if (options?.hideKnobAndValue) {
    return '€—';
  }

  return options?.noFormatting
    ? value
    : formatNumber(value, options?.shortenNumbers, nDigitsShown);
};
